const MAPBOX_ACCOUNT = process.env.GATSBY_MAPBOX_ACCOUNT
const MAPBOX_STYLE_ID = process.env.GATSBY_MAPBOX_STYLE_ID
const MAPBOX_TOKEN = process.env.GATSBY_MAPBOX_API_TOKEN

/**
 * Summary: Generate a Mapbox URL to get a static map image.
 */
export const getStaticMapImageUrl = ({
  account = MAPBOX_ACCOUNT,
  styleId = MAPBOX_STYLE_ID,
  token = MAPBOX_TOKEN,
  latitude = 0,
  longitude = 0,
  zoom = 0,
  width = 180,
  height = 180
}) => `https://api.mapbox.com/styles/v1/${account}/${styleId}/static/${longitude},${latitude},${zoom},0.00,0.00/${width}x${height}@2x?access_token=${token}`
  

  