import React from "react"
import orderBy from "lodash/orderBy"
import styled from "styled-components"

import useCities from "../hooks/use-cities"
import Layout from "../components/Layout"
import CityCard from "../components/CityCard"
import SEO from "../components/seo"
import CustomLink from "../components/CustomLink"
import CityMap from "../components/CityMap"
import FilterControls from "../components/FilterControls"
import FilterCitiesProvider from "../providers/FilteredCitiesProvider"

const getTitleForFilterType = (filterType, filter = "all") => {
  const defaultTitle = "All cities:"
  if(!filter || filter === "all") return defaultTitle
  switch(filterType) {
    case "policies": {
      return "Cities with policy area:"
    }
    case "activities": {
      return "Cities with innovation activity:"
    }
    case "skills": {
      return "Cities with innovation role:"
    }
    case "population": {
      return "Cities with population:"
    }
    default: {
      return defaultTitle
    }
  }
}

export const ExplorePageTemplate = ({
  children,
  renderMap = true,
  isPreview = false,
}) => (
  <Wrapper isPreview={false}>
    {renderMap && (
      <div className="page-header">
        <div className="map-header">
          <div className="map-container" id="map">
            <CityMap
              latitude={-20.52030856948001}
              longitude={12.93783407441528}
              initialZoom={0.9943369388059702}
            />
          </div>
        </div>
        <div className="filter-controls">
          <div className="container">
            <FilterControls />
          </div>
        </div>
      </div>
    )}
    <section className="cities" id="cities">
      <div className="cities__inner">
        <div className="container">
          {children}
          
        </div>
      </div>
    </section>
  </Wrapper>
)

const ExplorePage = () => {
  const cities = useCities();
  const sortedCities = orderBy(cities, ['title'], ['asc'])

  return (
    <Layout className="explore">
      <SEO title="Explore Cities" />
      <ExplorePageTemplate>
        <FilterCitiesProvider cities={sortedCities}>
          {({ filteredCities, filterType, filter }) => (
            <>
              <h2 className="cities__title">
                {getTitleForFilterType(filterType, filter)}
              </h2>
              <div className="city-list">
                {filteredCities.map(({ slug, title, summary, latitude, longitude }) => (
                  <CustomLink className="city-list__item" key={slug} href={`/cities/${slug}/`}>
                    <CityCard title={title} {...summary} latitude={latitude} longitude={longitude} />
                  </CustomLink>
                ))}
              </div>
            </>
          )}
        </FilterCitiesProvider>
      </ExplorePageTemplate>
    </Layout>
  )
}

const Wrapper = styled.div`
  
  .page-header {

    .map-header {
      position: relative;
      height: 24rem;
      background: #a0dedf;

      @media (min-width: ${({ theme }) => theme.mobile}) {
        height: 27rem;
      }

      @media (min-width: ${({ theme }) => theme.desktop}) {
        height: 43rem;
      }

      .map-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
    }
    
    .filter-controls {
      position: relative;
      width: 100%;
      z-index: 3;
      
      .container {
        padding: 0;

        @media (min-width: ${({ theme }) => theme.tablet}) {
          height: 0;
          padding: 0 3.125rem;
        }

        .filter-controls-wrapper {

          @media (min-width: ${({ theme }) => theme.tablet}) {
            transform: translate(0, calc(-100% - 1.75rem));

            body.is-ie & {
              transform: translate(0, -100%);
              margin-top: -1.75rem;
            }
          }
        }
      }
    }
  }

  .cities {
    padding-top: 8rem;
    margin-top: -8rem;
    
    &__inner {
      padding: 2rem 0 2.5rem;
      background: ${({ theme }) => theme.offWhite};

      @media (min-width: ${({ theme }) => theme.mobile}) {
        padding: 3rem 0 5rem;
      }
    }

    &__title {
      margin-bottom: 1.5rem;
      text-align: left;

      @media (min-width: ${({ theme }) => theme.mobile}) {
        margin-bottom: 2rem;
      }
    }
  }

  .city-list {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1.125rem;

    @media (min-width: ${({ theme }) => theme.mobile}) {
      grid-template-columns: 1fr 1fr;
    }

    @media (min-width: ${({ theme }) => theme.desktop}) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media (min-width: ${({ theme }) => theme.largeDesktop}) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    * {
      text-decoration: none;
    }

    body.is-ie & {
      margin: 0 -0.5625rem;

      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 0;
        clear: both;
      }

      .city-list__item {
        margin-bottom: 1.125rem;

        @media (min-width: ${({ theme }) => theme.mobile}) {
          width: 50%;
          float: left;
          padding: 0 0.5625rem;
        }

        @media (min-width: ${({ theme }) => theme.tablet}) {
          width: 33.33%;
        }

        @media (min-width: ${({ theme }) => theme.desktop}) {
          width: 25%;
        }
      }
    }
  }
`

export default ExplorePage
